import { formatPercentageWithDecimal } from '@/components/common/Spreadsheet/helpers';
import { getMetricFormatter } from '@/helpers/customCharts';
import { formatDateWithShortYear } from '@/helpers/dateFormatter';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';

/**
 * Returns HTML for populating a chart tooltip with the given data
 *
 * @param {string} title Title for the tooltip
 * @param {Date | string | number} dateValue Date for the tooltip
 * @param {string} value Value of the target, usually a formatted number
 * @param {string} [content] Supporting content, such as a list
 * @param {string} [scenario] Name of the scenario associated with the tooltip
 * @returns {string} HTML
 */
export function getTooltipHTML(
  title,
  dateValue,
  value,
  content = '',
  scenario,
) {
  const titleWithDate = `${title} - ${formatDateWithShortYear(dateValue)}`;
  return `
    <div class="ChartTooltip_Inner">
      ${scenario ? `<h3 class="ChartTooltip_Scenario">${scenario}</h3>` : ''}
      <h4 class="ChartTooltip_Title">${dateValue ? titleWithDate : title}</h4>
      <p class="ChartTooltip_TotalWrapper">${value}</p>
      ${content}
    </div>
  `;
}

/**
 * Returns the local x/y position for a chart tooltip, flipping left or right as
 * necessary to avoid overflowing the chart.
 *
 * @param {Object} params
 * @param {number} params.chartWidth Width of the chart container
 * @param {number} params.width Width of the tooltip element
 * @param {number} params.height Height of the tooltip element
 * @param {number} params.pointX X coordinate of the element triggering the
 *   tooltip
 * @param {number} params.pointY Y coordinate of the element triggering the
 *   tooltip
 * @param {offset} params.xOffset Desired horizontal offset from the trigger
 * @returns {Object} Local coordinates for the tooltip
 */
export function getTooltipPosition({
  chartWidth,
  width,
  height,
  pointX,
  pointY,
  offset,
}) {
  const y = pointY - height / 2;
  // Flip the tooltip when it would overflow the right edge of the chart,
  // unless the chart is small enough that it would overflow the left edge more
  const rightX = pointX + offset;
  const leftX = pointX - width - offset;
  const rightOverflow = rightX + width - chartWidth;
  const x =
    rightOverflow > 0 && (leftX > 0 || leftX > rightOverflow) ? leftX : rightX;
  return { x, y };
}

/**
 * Returns the metrics for a stacked column tooltip, consisting of the name and
 * value of each segment in the column
 *
 * @param {Highcharts.TooltipFormatterContextObject} context Tooltip context
 *   from Highcharts
 * @param {Object} [options] Options for configuring the metrics
 * @param {Object} [options.metadata] metadata of the chart
 * @param {Object[]} [options.additional] Additional metric definitions to add
 *   to the tooltip
 * @param {Object[]} [options.reverse] Option to reverse the metrics order.
 *   Default order is reversed.
 * @param {Function} [options.formatter] - Optional formatter for the tooltip
 *   KPI. Defaults to formatting as a monetary value.
 * @returns {Object[]} metrics for passing to ChartTooltip
 */
export function getStackedColumnTooltipMetrics(
  { series, x },
  {
    additional,
    reverse = true,
    formatter = metricFormatters.monetary,
    variables,
    metadata,
  } = {},
) {
  // Series are horizontal, but we need vertical data for the tooltip
  let list = series.chart.series.reduce(
    (accum, { name, options, points, type }) => {
      // handling for custom charts where the metrics have different units.
      let customFormatter = null;
      const isPercentChart = options.stacking === 'percent';
      if (variables?.length) {
        const { unit } =
          variables.find(
            (variable) =>
              variable.name === name ||
              metadata?.customVariableNames?.[variable.name] === name,
          ) || {};
        customFormatter = isPercentChart
          ? formatPercentageWithDecimal
          : getMetricFormatter(unit);
      }
      if (type === 'column' || type === 'bar' || type === 'area') {
        const scenarioName = options.stack;
        const point = points?.find((p) => p.x === x);
        if (!isEmptyOrNull(point?.y)) {
          const { color, rawY, y } = point;
          let metric = accum.find((m) => m.name === name);
          if (!metric) {
            metric = {
              name,
              colors: {},
              getValue: {},
              formatter,
            };
            accum.push(metric);
          }
          const value =
            options.stacking === 'percent' ? point.percentage : rawY ?? y;
          metric.colors[scenarioName] = color.pattern?.backgroundColor ?? color;
          metric.getValue[scenarioName] = () => value;
          metric.formatter = customFormatter || formatter;
        }
      }
      return accum;
    },
    [],
  );

  if (reverse) {
    list = list.reverse();
  }

  if (additional) list = list.concat(additional);

  return list;
}

/**
 * Callback function for positioning the Highcharts tooltip for a stacked
 * horizontal bar chart. As the tooltip will display the same information
 * regardless of cursor position, this function places the tooltip in the center
 * of the chart. Assumes 'outside' option is set to TRUE, either in the local
 * tooltip or global Highcharts options.
 *
 * @see https://api.highcharts.com/highcharts/tooltip.positioner
 * @see Highcharts.TooltipPositionerCallbackFunction
 */
export function getBarTooltipPositioner(labelWidth, labelHeight, { plotY }) {
  const { chart, container } = this;
  const { renderTo, series } = chart;

  const chartTestId = renderTo.dataset.testid;
  const tooltipDataAttrs = container.dataset;
  if (chartTestId && !tooltipDataAttrs.testid) {
    tooltipDataAttrs.testid = `${chartTestId}-tooltip`;
  }

  const { width } = chart.container.getBoundingClientRect();
  const x = width / 2 - labelWidth / 2;

  let barY = plotY;
  const [{ columnMetrics }] = series;
  if (columnMetrics) barY -= columnMetrics.paddedWidth / 2;
  const y = barY - labelHeight;
  return { x, y };
}
