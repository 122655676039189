import { columnTooltipPositioner } from '@bill/cashflow.react/chart-helpers';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { getStackedColumnTooltipMetrics } from '@/components/Charts/tooltips';
import { CASH_IN_TYPE } from '@/constants/cashInOut';
import useScenarioQueries from '@/hooks/useScenarioQueries';
import useTypedSelector from '@/hooks/useTypedSelector';
import useCashInChartQuery from '@/pages/Dashboard/charts/useCashInChartQuery';
import {
  cashAccountsColorMap,
  CHART_TITLE,
  TOTAL_CASH_IN_MAIN_METRIC,
} from '@/pages/Dashboard/constants/cashIn';

const metrics = (context) =>
  getStackedColumnTooltipMetrics(context, {
    additional: [
      {
        name: CHART_TITLE,
        key: TOTAL_CASH_IN_MAIN_METRIC,
        isMainMetric: true,
      },
    ],
  });

/**
 * @typedef {{
 *   isLoading?: boolean;
 *   data: {
 *     totalCashIn: number;
 *   };
 * }} QueryStateChangeData
 */

/**
 * @typedef {{
 *   plotOptions?: Highcharts.PlotOptions;
 *   axisStyles?: Highcharts.CSSObject | {};
 *   onQueryStateChange?: (
 *     data: import('@/hooks/useScenarioQueries').QueryStateChangeData[],
 *   ) => void;
 *   tooltipOptions?: Object;
 *   exportBtn: Object;
 *   onChartCreated?: (chart: Highcharts.Chart) => void;
 * }} CashInChart
 */

/**
 * Fetches and renders a bar chart showing Cash In
 *
 * @example
 *   <CashInChart
 *     exportBtn={exportBtn}
 *     plotOptions={plotOptions}
 *     tooltipOptions={tooltipOptions}
 *   />;
 *
 * @type {(props: CashInChart) => React.ReactElement}
 */
function CashInChart({
  tooltipOptions,
  exportBtn,
  plotOptions,
  axisStyles,
  onQueryStateChange,
  onChartCreated,
}) {
  const { startDate, endDate, timePeriod } = useTypedSelector(
    ({ shared }) => shared,
  );
  const scenarioQueries = useCashInChartQuery({ filter: CASH_IN_TYPE });
  const [base] = scenarioQueries;
  const scenarioId = base?.scenario.scenarioId;
  useScenarioQueries(scenarioQueries, onQueryStateChange);

  return (
    <DateChart
      id="cashin-xyChart"
      data-testid="cashin-xyChart-test-id"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={metrics}
          positioner={columnTooltipPositioner}
          valueKey={TOTAL_CASH_IN_MAIN_METRIC}
          timePeriod={timePeriod}
          endDate={endDate}
          {...tooltipOptions}
        />
      }
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      timePeriod={timePeriod}
      startDate={startDate}
      endDate={endDate}
      onChartCreated={onChartCreated}
    >
      {scenarioQueries.map(
        ({ data, scenario }, idx) =>
          data && (
            <DateChart.StackedColumnSeries
              key={scenario.scenarioId}
              data={data.data}
              isComparison={scenario?.scenarioId !== scenarioId}
              scenario={scenario}
              colors={cashAccountsColorMap}
            />
          ),
      )}
    </DateChart>
  );
}

export default CashInChart;
