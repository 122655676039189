import { Component, createRef } from 'react';
import { classNames } from '@/helpers';

const LAYOUT_CLASS_NAME = 'react-grid-layout';

export default function WidthProvideRGL(ComposedComponent) {
  return class WidthProvider extends Component {
    constructor(props) {
      super(props);

      this.state = { width: 1280 };
      this.elementRef = createRef();
      this.mounted = false;
      this.onWindowResize = this.onWindowResize.bind(this);
    }

    componentDidMount() {
      this.mounted = true;
      window.addEventListener('resize', this.onWindowResize);
      this.onWindowResize();
    }

    componentWillUnmount() {
      this.mounted = false;
      window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize() {
      if (!this.mounted) return;
      const node = this.elementRef.current;
      if (node instanceof HTMLElement && node.offsetWidth) {
        this.setState({ width: node.offsetWidth });
      }
    }

    render() {
      const { measureBeforeMount, ...rest } = this.props;
      if (measureBeforeMount && !this.mounted) {
        return (
          <div
            id="placeholder"
            className={classNames(this.props.className, LAYOUT_CLASS_NAME)}
            style={this.props.style}
            ref={this.elementRef}
          />
        );
      }

      return (
        <ComposedComponent
          innerRef={this.elementRef}
          {...rest}
          {...this.state}
        />
      );
    }
  };
}
