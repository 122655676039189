import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { compact } from 'react-grid-layout/build/utils';
import 'react-grid-layout/css/styles.css';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { connect, useDispatch } from 'react-redux';
import { Prompt, useRouteMatch, useHistory } from 'react-router-dom';
import EditDashboardIcon from '@bill/cashflow.assets/edit-dashboard';
import { AnimatePresence, m as motion } from 'framer-motion';
import Highcharts from 'highcharts';
import { RESET_CLEANUP_ROUTE } from '@/actionTypes/dashboard';
import {
  getDashboardsAction as getDashboards,
  toggleEditMode,
  addDashboardAction as addDashboard,
  setSelectedDashboardIdAction,
} from '@/actions/dashboard';
import AddUpdateDashboardSidebar from '@/components/AddUpdateDashboardSidebar';
import {
  CUSTOM_CHART,
  SYSTEM_CHART,
} from '@/components/common/ChartList/constants';
import IconButton from '@/components/common/IconButton';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { DASHBOARD_PATH } from '@/constants/pages';
import { classNames } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useElementSize from '@/hooks/useElementSize';
import useIsFPALite from '@/hooks/useIsFPALite';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useOneColor from '@/hooks/useOneColor';
import useSelectedDashboard from '@/hooks/useSelectedDashboard';
import useUpdateDashboard from '@/hooks/useUpdateDashboard';
import CustomChartGlance from '@/pages/Dashboard/CustomChartGlance';
import useCustomChartsQuery from '@/pages/Dashboard/charts/useCustomChartsQuery';
import {
  GUTTER,
  layoutConstraints,
  NUM_COLS,
  ROW_HEIGHT,
} from '@/pages/Dashboard/constants/gridConstants';
import DEFAULT_LAYOUT, {
  CASHFLOW_LAYOUT,
} from '@/pages/Dashboard/defaultLayout';
import {
  transformToLayoutItems,
  getSortedChartLayoutWithComputedMeta,
} from '@/pages/Dashboard/helpers';
import STOCK_CHART_MAP from '@/pages/Dashboard/stockChartMap';
import useGridBackground from '@/pages/Dashboard/useGridBackground';
import { deleteDashboard } from '@/services/dashboard.service';
import { useDashboardContext } from './DashboardContext';
import DashboardSelector from './DashboardSelector';
import DashboardToolbar from './DashboardToolbar';
import WidthProvider from './WidthProvider';
import { ReactComponent as AddChartForecastingIcon } from '@/assets/icons/svg/add_chart_forecasting.svg';
import { ReactComponent as EditLayoutForecastingIcon } from '@/assets/icons/svg/edit_layout-forecasting.svg';
import './DashboardLanding.scss';

/** @type {Partial<import('react-grid-layout').ReactGridLayoutProps>} */
const GRID_OPTIONS = {
  cols: NUM_COLS,
  containerPadding: [0, 0],
  margin: [GUTTER, GUTTER],
  measureBeforeMount: true,
  rowHeight: ROW_HEIGHT,
  compactType: 'vertical',
};

/**
 * Transforms layout items to dashboard items
 *
 * @param {import('@/types/dashboard').LayoutItem[]} layoutItems
 * @returns {import('@/components/common/ChartList/types').Chart[]}
 */
const getDashboardItemsForRequest = (layoutItems) => {
  return layoutItems.map(({ i, x, y, w, h }) => {
    return {
      key: i,
      type: STOCK_CHART_MAP[i] ? SYSTEM_CHART : CUSTOM_CHART,
      // When new charts are added they are assigned a `meta` value of null.
      // Here we pass along the null so that we can set a default layout value
      // for it later.
      //
      // If we already have layout coodinates for an existing chart, pass those
      // along too
      meta: [x, y, w, h].some(isEmptyOrNull) ? null : { x, y, w, h },
    };
  });
};

const GridLayout = WidthProvider(ReactGridLayout);

const DashboardLanding = ({
  scenarioId,
  navBarExpanded,
  copilotExpanded,
  isEditing,
  dashboards,
  hasNoLayout,
  toggleChartsSidebar,
  setSelectedDashboardId,
  shouldCleanUpRoute,
}) => {
  const updateDashboard = useUpdateDashboard();
  const match = useRouteMatch();
  const history = useHistory();
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const container = useRef(null);
  const [layout, setLayout] = useState([]);
  const [layoutItems, setlayoutItems] = useState([]);
  const { setToggleChartsSidebar, onEditChart, onAddChart } =
    useDashboardContext();
  const [addNewDashboardErrorMessage, setAddNewDashboardErrorMessage] =
    useState('');
  const [dashboardDeleteName, setDashboardDeleteName] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  /** @type {import('@/types/dashboard').Dashboard} */
  const selectedDashboard = useSelectedDashboard();
  const [dashboardToEdit, setDashboardToEdit] = useState(null);
  const [isAddUpdateDashboardLoading, setIsAddUpdateDashboardLoading] =
    useState(false);
  const [showDashboardList, setShowDashboardList] = useState(false);
  useBeforeUnload(isEditing);
  const isOneColorEnabled = useOneColor();
  const size = useElementSize(container);
  const readyForGrid = !!size.contentRect?.width;

  const isLiteProduct = useIsFPALite();

  const chartDefaultLayout = isLiteProduct ? CASHFLOW_LAYOUT : DEFAULT_LAYOUT;

  /** @type {import('@/types/dashboard').Dashboard} */
  const defaultDashboard = useMemo(
    () => dashboards.find(({ isDefault }) => isDefault),
    [dashboards],
  );

  useEffect(() => {
    if (shouldCleanUpRoute) {
      history.replace(DASHBOARD_PATH);
      dispatch({ type: RESET_CLEANUP_ROUTE });
    }
  }, [shouldCleanUpRoute, history, dispatch]);

  useEffect(() => {
    setSelectedDashboardId(match.params.dashboardId ?? defaultDashboard?.id);
  }, [
    dashboards,
    match.params.dashboardId,
    setSelectedDashboardId,
    defaultDashboard,
  ]);

  const hasToolbarEditAccess = useNonDashboardWritePermission();

  useEffect(() => {
    // Disable chart animations while editing the layout for performance
    Highcharts.setOptions({
      chart: { animation: !isEditing },
      plotOptions: {
        series: {
          animation: !isEditing,
          enableMouseTracking: !isEditing,
        },
      },
    });
  }, [isEditing]);

  const saveLayout = useCallback(
    async (newLayout, { isUpdate = true } = {}) => {
      const items = getDashboardItemsForRequest(newLayout);
      const dasboard = {
        items,
        id: selectedDashboard?.id,
        name: selectedDashboard?.name,
      };
      try {
        if (isUpdate) {
          await updateDashboard(dasboard.id, dasboard);
        } else {
          await dispatch(addDashboard(scenarioId, dasboard));
        }
        setAddNewDashboardErrorMessage('');
      } catch ({ message }) {
        setAddNewDashboardErrorMessage(message);
      }
    },
    [
      dispatch,
      scenarioId,
      selectedDashboard?.id,
      selectedDashboard?.name,
      updateDashboard,
    ],
  );

  const customChartsQuery = useCustomChartsQuery();

  useEffect(() => {
    if (!hasNoLayout && (customChartsQuery.isFetching || !dashboards.length)) {
      setlayoutItems([]);
      return;
    }

    const customCharts = customChartsQuery.data ?? [];

    if (isEditing) return;

    // User has a custom layout
    if (selectedDashboard) {
      const chartLayout = getSortedChartLayoutWithComputedMeta(
        selectedDashboard.items,
        customCharts,
      );
      setlayoutItems(chartLayout);
      return;
    }

    // No custom layout yet, build a default
    const numCharts = customCharts.length;
    const numDangling = numCharts > 5 ? (numCharts - 1) % 3 : 0;
    let colCounter = 0;
    let customChartRows = 0;
    const customChartItems = customCharts.map((chartInfo, idx) => {
      let w = 6;
      // One lone chart on the last (or only) row
      if (
        numCharts === 1 ||
        (numDangling > 0 && numDangling < 2 && idx === numCharts - 1)
      ) {
        w = NUM_COLS;
      } else if (
        // 3-across for 3 or 6 charts
        [3, 6].includes(numCharts) ||
        // Every other row is 3-across, except when there's 2 on the last row
        (numCharts > 4 && idx % 5 >= 2 && idx < numCharts - numDangling)
      ) {
        w = 4;
      }

      const x = colCounter % NUM_COLS;
      const y =
        Math.floor(colCounter / NUM_COLS) * layoutConstraints.DEFAULT_ROW_SPAN;
      colCounter += w;

      return {
        key: chartInfo.id,
        meta: {
          x,
          y,
          w,
          h: layoutConstraints.DEFAULT_ROW_SPAN,
        },
        chartInfo,
      };
    });

    customChartRows =
      Math.ceil(colCounter / NUM_COLS) * layoutConstraints.DEFAULT_ROW_SPAN;

    // Shift the stock charts down to make room for the custom charts
    const stockChartItems = chartDefaultLayout.map(({ meta, ...props }) => ({
      meta: {
        ...meta,
        y: meta.y + customChartRows,
      },
      ...props,
    }));

    setlayoutItems([...stockChartItems, ...customChartItems]);
  }, [
    customChartsQuery,
    dashboards,
    isEditing,
    selectedDashboard,
    hasNoLayout,
    chartDefaultLayout,
  ]);

  const savedLayout = useMemo(
    () => {
      const transformedItems = transformToLayoutItems(layoutItems);
      const compactedLayout = compact(
        transformedItems,
        GRID_OPTIONS.compactType,
        GRID_OPTIONS.cols,
      );

      return compactedLayout;
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [layoutItems, selectedDashboard],
  );

  useEffect(() => {
    if (!savedLayout.length || isEditing) return;
    setLayout(savedLayout);
    const hasNullMeta = selectedDashboard?.items?.some((item) => !item.meta);

    if (hasNoLayout) {
      // If this scenario's layout has never been edited, save the default layout
      // so that adding custom charts won't screw it up.
      saveLayout(savedLayout, { isUpdate: false });
    } else if (hasNullMeta) {
      // If this scenario's dashboard items has null meta
      // save the computed layout to persist the populated meta
      // so that duplicating scenarios maintains the layout order
      saveLayout(savedLayout);
    }
  }, [hasNoLayout, isEditing, saveLayout, savedLayout, selectedDashboard]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [navBarExpanded, copilotExpanded]);

  // This is unusual, but react-grid-layout will not accept a wrapper component
  const widgets = useMemo(
    () =>
      layoutItems.map(({ key, chartInfo }) => {
        const Component = STOCK_CHART_MAP[key];
        if (Component) return <Component key={key} />;

        return (
          <CustomChartGlance
            key={key}
            chartInfo={chartInfo}
            onDeleted={customChartsQuery.refetch}
            onEdit={onEditChart}
          />
        );
      }),
    /**
     * Including isEditing in the dependency array is necessary to force
     * react-grid-layout to toggle its draggable/resizable props
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
    [customChartsQuery.refetch, isEditing, layoutItems, selectedDashboard],
  );

  const background = useGridBackground(size.contentRect?.width);

  const handleAddUpdateCharts = useCallback(
    async ({ selectedCharts, name, id, isDefault }) => {
      const charts = Object.values(selectedCharts);

      const computedCharts = getSortedChartLayoutWithComputedMeta(charts);
      const transformedLayout = transformToLayoutItems(computedCharts);
      const compactedLayout = compact(
        transformedLayout,
        GRID_OPTIONS.compactType,
        GRID_OPTIONS.cols,
      );
      if (isEditing) {
        setlayoutItems(charts);
        setLayout(compactedLayout);
        setToggleChartsSidebar(false);
        return;
      }
      const items = getDashboardItemsForRequest(compactedLayout);
      const dashboard = { id, items, name, isDefault };
      try {
        setIsAddUpdateDashboardLoading(true);
        if (id) {
          await updateDashboard(dashboard.id, dashboard);
        } else {
          await dispatch(addDashboard(scenarioId, dashboard));
        }
        setToggleChartsSidebar(false);
        setAddNewDashboardErrorMessage('');
        setDashboardToEdit(null);
      } catch ({ message }) {
        setAddNewDashboardErrorMessage(message);
      } finally {
        setIsAddUpdateDashboardLoading(false);
      }
    },
    [dispatch, isEditing, scenarioId, setToggleChartsSidebar, updateDashboard],
  );

  const handleOnEdit = (id) => {
    const dasboardById = dashboards.find(
      ({ id: dashboardId }) => dashboardId === id,
    );
    setDashboardToEdit(dasboardById);
    setToggleChartsSidebar(true);
  };

  const toggleShowConfirmDeleteModal = (name) => {
    setDashboardDeleteName(name);
    setShowDeleteConfirmation(true);
  };

  const handleDashboardDelete = async () => {
    await deleteDashboard(scenarioId, dashboardDeleteName);
    await dispatch(getDashboards(scenarioId));
    if (selectedDashboard.name === dashboardDeleteName) {
      dispatch(setSelectedDashboardIdAction(defaultDashboard.id));
    }
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <div ref={container}>
        <Prompt
          when={isEditing}
          message="Are you sure you want to leave this page? Changes to the dashboard won't be saved"
        />
        <DashboardToolbar>
          <DashboardSelector
            onCreateNew={() => setToggleChartsSidebar(true)}
            options={dashboards}
            onEdit={handleOnEdit}
            toggleShowConfirmDeleteModal={toggleShowConfirmDeleteModal}
            disabled={isEditing}
            showDashboardList={showDashboardList}
            onToggle={setShowDashboardList}
          />
          {hasToolbarEditAccess && (
            <>
              {isOneColorEnabled && (
                <IconButton
                  className="DashboardToolbar_Button-forecasting"
                  onClick={onAddChart}
                  Icon={AddChartForecastingIcon}
                  label="Create New Chart"
                  data-testid="createChart"
                />
              )}

              <IconButton
                className={classNames(
                  isOneColorEnabled
                    ? 'DashboardToolbar_Button-forecasting'
                    : 'IconButton-large',
                )}
                Icon={
                  isOneColorEnabled
                    ? EditLayoutForecastingIcon
                    : EditDashboardIcon
                }
                disabled={showDashboardList}
                label="Edit Layout"
                onClick={() => dispatch(toggleEditMode(true))}
                data-testid="editLayout"
              />
            </>
          )}
          <AnimatePresence>
            {isEditing && (
              <>
                <motion.button
                  className="Button Button-cancelLink"
                  onClick={() => {
                    customChartsQuery.refetch();
                    setLayout(savedLayout);
                    dispatch(toggleEditMode(false));
                  }}
                >
                  Cancel
                </motion.button>
                <motion.button
                  className="Button Button-primaryLink"
                  onClick={() => {
                    dispatch(toggleEditMode(false));
                    saveLayout(layout);
                  }}
                >
                  Save
                </motion.button>
              </>
            )}
          </AnimatePresence>
        </DashboardToolbar>
        {readyForGrid && (
          <GridLayout
            {...GRID_OPTIONS}
            className={classNames(
              'DashboardLanding',
              isEditing && 'DashboardLanding-editing',
            )}
            isDraggable={isEditing}
            isResizable={isEditing}
            layout={layout}
            onLayoutChange={setLayout}
            style={isEditing ? { background } : undefined}
          >
            {widgets}
          </GridLayout>
        )}
      </div>
      <AddUpdateDashboardSidebar
        dashboard={dashboardToEdit}
        open={toggleChartsSidebar}
        onApply={handleAddUpdateCharts}
        onClose={() => {
          setDashboardToEdit(null);
          setToggleChartsSidebar(false);
        }}
        error={addNewDashboardErrorMessage}
        setError={setAddNewDashboardErrorMessage}
        isLoading={isAddUpdateDashboardLoading}
      />
      {showDeleteConfirmation && (
        <ModalConfirmation
          id="dashboard-delete-modal"
          onCancel={() => setShowDeleteConfirmation(false)}
          onAction={handleDashboardDelete}
          title="Delete Dashboard"
          actionBtnTxt="Delete"
        >
          Are you sure you would like to delete this dashboard? This action
          cannot be undone. Any custom charts you have added here will need to
          be added into a new dashboard.
        </ModalConfirmation>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    scenarioId: state.scenario.scenarioId,
    navBarExpanded: state.ui.navBarExpanded,
    copilotExpanded: state.ui.copilotExpanded,
    isEditing: state.dashboard.isEditing,
    dashboards: state.dashboard.dashboards,
    hasNoLayout: state.dashboard.hasNoLayout,
    shouldCleanUpRoute: state.dashboard.shouldCleanUpRoute,
  };
};

export default connect(mapStateToProps, {
  setSelectedDashboardId: setSelectedDashboardIdAction,
})(DashboardLanding);
