import { useEffect } from 'react';
import { YAxis } from 'react-jsx-highcharts';
import { connect } from 'react-redux';
import EmployeesEmptyIcon from '@bill/cashflow.assets/employees-empty';
import { columnTooltipPositioner } from '@bill/cashflow.react/chart-helpers';
import { subscribeToActualsUpdateAction } from '@/actions/actuals';
import {
  subscribeToEmployeeUpdateAction,
  subscribeToHiringDriverForecastAction,
} from '@/actions/employees';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { LABEL_STYLES } from '@/components/Charts/chartDefaults';
import { getContrastingShade } from '@/components/Charts/colors';
import { chartTypes } from '@/components/Charts/constants';
import { getYAxisConfig } from '@/components/Charts/helpers';
import EmptyData from '@/components/common/EmptyData';
import { actualsFamily } from '@/constants/actuals';
import metricFormatters from '@/helpers/metricFormatters';
import useWsSubscription from '@/hooks/useWsSubscription';
import useTotalPayrollQuery from '@/pages/Dashboard/charts/useTotalPayrollQuery';

const TOTAL_PAYROLL = 'Total Payroll';
const NUM_EMPLOYEES = 'Number of Employees';

const YAXIS_DEFAULTS = getYAxisConfig();

const metrics = [
  {
    name: NUM_EMPLOYEES,
    key: 'headcount',
    formatter: metricFormatters.count,
  },
  {
    isMainMetric: true,
    formatter: metricFormatters.monetary,
    key: 'payroll',
    name: TOTAL_PAYROLL,
  },
];

/**
 * Fetches and renders a combination column and line chart showing payroll
 *
 * @example
 *   <PayrollChart exportBtn={exportBtn} />;
 */
function PayrollChart({
  scenarioId,
  startDate,
  endDate,
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  subscribeToHiringDriverForecast,
  subscribeToActualsUpdate,
  subscribeToEmployeeUpdate,
  onQueryStateChange,
  timePeriod,
}) {
  const scenarioQueries = useTotalPayrollQuery();
  const [base] = scenarioQueries;
  const loading = scenarioQueries.every(({ isLoading }) => isLoading);
  const hasData =
    loading || base.data?.hasEmployees || base.data?.totalPayroll > 0;

  useEffect(() => {
    if (base) onQueryStateChange?.({ ...base, hasData });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [base]);

  useWsSubscription(
    () =>
      subscribeToHiringDriverForecast(scenarioId, () => {
        base.refetch();
      }),
    [scenarioId],
  );

  useWsSubscription(
    () =>
      subscribeToActualsUpdate(scenarioId, actualsFamily.PAYROLL, () => {
        base.refetch();
      }),
    [scenarioId],
  );

  useWsSubscription(
    () =>
      subscribeToEmployeeUpdate(scenarioId, () => {
        base.refetch();
      }),
    [scenarioId],
  );

  const yAxisConfigs = {
    ...YAXIS_DEFAULTS,
    labels: {
      ...YAXIS_DEFAULTS.labels,
      style: axisStyles || LABEL_STYLES,
    },
  };

  return (
    <>
      {hasData ? (
        <DateChart
          className={className}
          data-testid="totalPayroll"
          loading={loading}
          tooltip={
            <ChartTooltip
              metrics={metrics}
              positioner={columnTooltipPositioner}
              valueKey="payroll"
              timePeriod={timePeriod}
              endDate={endDate}
              {...tooltipOptions}
            />
          }
          plotOptions={plotOptions}
          axisStyles={axisStyles}
          ref={exportBtn}
          timePeriod={timePeriod}
          startDate={startDate}
          endDate={endDate}
        >
          <YAxis {...yAxisConfigs}>
            <YAxis.Title>Total Payroll Costs</YAxis.Title>
            {scenarioQueries.map(
              ({ data, scenario }, idx) =>
                data && (
                  <DateChart.Series
                    key={scenario.scenarioId}
                    type={chartTypes.COLUMN}
                    data={data.totalPayrollPerMonth}
                    index={idx}
                    isComparison={scenario?.scenarioId !== scenarioId}
                    metric={TOTAL_PAYROLL}
                    scenario={scenario}
                  />
                ),
            )}
          </YAxis>
          <YAxis opposite allowDecimals={false} min={0}>
            <YAxis.Title>Number of Employees</YAxis.Title>
            {scenarioQueries.map(
              ({ data, scenario }, idx) =>
                data && (
                  <DateChart.Series
                    key={scenario.scenarioId}
                    type={chartTypes.LINE}
                    color={getContrastingShade(scenario?.color)}
                    data={data.totalHeadcountPerMonth}
                    formatter={metricFormatters.count}
                    index={idx}
                    metric={NUM_EMPLOYEES}
                    scenario={scenario}
                    isComparison={idx === 1}
                  />
                ),
            )}
          </YAxis>
        </DateChart>
      ) : (
        <EmptyData Icon={EmployeesEmptyIcon}>
          Add Employees for this chart to populate
        </EmptyData>
      )}
    </>
  );
}

function mapStateToProps({ scenario, shared }) {
  const { scenarioId } = scenario;
  return {
    scenarioId,
    startDate: shared.startDate,
    endDate: shared.endDate,
    timePeriod: shared.timePeriod,
  };
}

export default connect(mapStateToProps, {
  subscribeToHiringDriverForecast: subscribeToHiringDriverForecastAction,
  subscribeToActualsUpdate: subscribeToActualsUpdateAction,
  subscribeToEmployeeUpdate: subscribeToEmployeeUpdateAction,
})(PayrollChart);
